import amphitheater from "./amphitheater.svg";
import chevronDoubleDown from "./chevron-double-down.svg";
import chevronDoubleLeft from "./chevron-double-left.svg";
import chevronDoubleRight from "./chevron-double-right.svg";
import chevronDoubleUp from "./chevron-double-up.svg";
import chevronDown from "./chevron-down.svg";
import chevronLeft from "./chevron-left.svg";
import chevronRight from "./chevron-right.svg";
import chevronUp from "./chevron-up.svg";
import commentAttention from "./comment-attention.svg";
import commentText from "./comment-text.svg";
import documentEuro from "./document-euro.svg";
import documents from "./documents.svg";
import documentOk from "./document-ok.svg";
import envelope from "./envelope.svg";
import euroCircle from "./euro-circle.svg";
import gewinner from "./gewinner.svg";
import request from "./request.svg";
import requests from "./requests.svg";
import thLarge from "./th-large.svg";
import time from "./time.svg";
import user from "./user.svg";

export default {
  amphitheater,
  "chevron-double-down": chevronDoubleDown,
  "chevron-double-left": chevronDoubleLeft,
  "chevron-double-right": chevronDoubleRight,
  "chevron-double-up": chevronDoubleUp,
  "chevron-down": chevronDown,
  "chevron-left": chevronLeft,
  "chevron-right": chevronRight,
  "chevron-up": chevronUp,
  "comment-text": commentText,
  "comment-attention": commentAttention,
  "document-euro": documentEuro,
  documents,
  "document-ok": documentOk,
  envelope,
  "euro-circle": euroCircle,
  gewinner,
  request,
  requests,
  "th-large": thLarge,
  time,
  user,
};
