import AM from "./AM";
import AR from "./AR";

const AM_ROUTES = AM || [];
const AR_ROUTES = AR || [];

export default [
  ...AM_ROUTES,
  ...AR_ROUTES,
];
