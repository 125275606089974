const VideoDetails = () => import(/* webpackChunkName: "VideoDetails" */ "../../../views/Video/VideoDetails/VideoDetails.vue");

export default [
  {
    path: "/videos/:id/",
    name: "root.am_videos.details",
    component: VideoDetails,
    meta: {
      iframe: true,
    },
  },
];
