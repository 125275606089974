import AButton from "aloha-vue/src/AButton/AButton";
import AngularLink from "../../global/components/AngularLink/AngularLink.vue";
import MenuElementLevel1 from "./MenuElementLevel1/MenuElementLevel1.vue";
import PuxCloak from "../../global/components/PuxCloak/PuxCloak.vue";
import PuxTranslate from "../../global/components/PuxTranslate/PuxTranslate.vue";

import AHttpAPI from "aloha-vue/src/compositionAPI/AHttpAPI";
import MobileMenuAPI from "../TheMenu/MobileMenuAPI";
import TheMenuAPI from "../TheMenu/TheMenuAPI";
import MenuOrderByAPI from "../TheMenuNew/compositionAPI/MenuOrderByAPI";

import EventBus from "aloha-vue/src/utils/EventBus";

// @vue/component
export default {
  name: "TheMenuHorizontal",
  components: {
    AButton,
    AngularLink,
    MenuElementLevel1,
    PuxCloak,
    PuxTranslate,
  },
  setup() {
    const {
      classParent,
      closeMobileMenu,
      openMobileMenu,
      refH1,
      statusMobileMenuOpen,
    } = MobileMenuAPI();

    const {
      checkActiveRouteAndSetPageTitle,
      filterMenuWithInstancePages,
      filterMenuWithPermissions,
      startOptions,
      stateChildActivePk,
      stateParentActivePk,
      updateState,
    } = TheMenuAPI({
      statusMobileMenuOpen,
    });

    const {
      orderByMenu,
    } = MenuOrderByAPI();

    const {
      getListHttp,
    } = AHttpAPI();

    return {
      classParent,
      closeMobileMenu,
      checkActiveRouteAndSetPageTitle,
      filterMenuWithInstancePages,
      filterMenuWithPermissions,
      getListHttp,
      openMobileMenu,
      orderByMenu,
      refH1,
      startOptions,
      stateChildActivePk,
      stateParentActivePk,
      statusMobileMenuOpen,
      updateState,
    };
  },
  data() {
    return {
      statusLoading: true,
      statusMenuActive: false,
      statusShowButtonToggle: true,
      statusShowMenuDetails: undefined,
      statusLinkDisabled: false,
      user: {},
      menuDefault: [],
      menu: [],
    };
  },
  created() {
    this.loadData();
    this.setEventBuses();
  },
  beforeUnmount() {
    this.deleteEventBuses();
  },
  methods: {
    loadData() {
      Promise.all([
        this.getListHttp({
          url: "menu_readonly/",
          urlParams: {
            top: true,
          },
          abortable: false,
        }),
      ]).then(
        responses => {
          const MENU_DEFAULT = this.filterMenuWithInstancePages({ response: responses[0] });
          this.menuDefault = this.orderByMenu({ menu: MENU_DEFAULT });
          this.filterMenuWithPermissionsLocal();
          this.checkActiveRouteAndSetPageTitle({ menu: this.menu });
        }
      ).then(
        () => {
          this.statusLoading = false;
        }
      );
    },

    setEventBuses() {
      EventBus.$on("updateProfile", this.filterMenuWithPermissionsLocal);
      EventBus.$on("openMobileMenu", this.openMobileMenu);
      EventBus.$on("updateState", this.updateStateLocal);
    },

    deleteEventBuses() {
      EventBus.$off("updateProfile", this.filterMenuWithPermissionsLocal);
      EventBus.$off("openMobileMenu", this.openMobileMenu);
      EventBus.$off("updateState", this.updateStateLocal);
    },

    setStatusLinkDisabled() {
      this.statusLinkDisabled = true;
      this.closeMobileMenu();
      setTimeout(() => {
        this.statusLinkDisabled = false;
      }, 1000);
    },

    filterMenuWithPermissionsLocal() {
      this.menu = this.filterMenuWithPermissions({ menuDefault: this.menuDefault });
    },

    updateStateLocal() {
      this.updateState({ menu: this.menu });
    },
  },
};
