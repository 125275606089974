import ADropdown from "aloha-vue/src/ADropdown/ADropdown";
import MenuElementMixin from "../../TheMenu/MenuElementMixin";
import MenuElementLevel2 from "../MenuElementLevel2/MenuElementLevel2.vue";

// @vue/component
export default {
  name: "MenuElement",
  components: {
    ADropdown,
    MenuElementLevel2,
  },
  mixins: [
    MenuElementMixin,
  ],
  props: {
    stateParentActivePk: {
      type: String,
      default: undefined,
      required: false,
    },
    stateChildActivePk: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  computed: {
    liId() {
      return `li_${ this.data.pk }`;
    },

    statusHasChildren() {
      return this.children.length > 0;
    },

    children() {
      return this.data.children || [];
    },
  },
  methods: {
    onClickLink() {
      this.setStatusLinkDisabled();
    },
  },
};
